<script setup lang="ts">
import { scrollToPosition } from '@mop/shared/utils/util';

defineOptions({
  name: 'FormRegister',
});

const { stepDataRef, sendRegistration, loadingRef } = useRegistrationForm();
const showThankYouPageRef = ref(false);
const showErrorMessage = ref(false);

function goToNextStep() {
  stepDataRef.value.component = stepDataRef.value.nextComponent;
}

function goToPrevStep() {
  stepDataRef.value.component = stepDataRef.value.previousComponent;
}

async function sendForm() {
  showErrorMessage.value = false;
  const isSent = await sendRegistration();
  if (isSent) {
    showThankYouPageRef.value = true;
    stepDataRef.value.component = '';
  } else {
    showErrorMessage.value = true;
  }
}

const loadMe = ref(true);
const TRANSITION_TIME = 300;
watch(
  () => stepDataRef.value.component,
  () => {
    loadMe.value = false;
    scrollToPosition(0, true);
    setTimeout(() => {
      loadMe.value = true;
    }, TRANSITION_TIME);
  },
);
</script>

<template>
  <Transition name="fade">
    <div v-if="showThankYouPageRef">
      <FormRegisterThankYou />
    </div>
    <div v-else-if="loadMe" class="form-step" :style="{ '--animation-speed': `${TRANSITION_TIME}ms` }">
      <Ui2Notification
        v-if="showErrorMessage"
        status="error"
        :show-icon="true"
        :title="$mopI18n.t('common.error.title')"
      >
        {{ $mopI18n.t('common.error.submit') }}
      </Ui2Notification>

      <Ui2Button
        v-if="stepDataRef.previousComponent"
        class="form-step__back"
        :label="$mopI18n.t('common.action.back')"
        appearance="primary"
        type="ghost"
        size="lg"
        icon-start="arrow-left"
        @click="goToPrevStep"
      />

      <div v-if="stepDataRef.title" :class="['form-step__title']">
        {{ stepDataRef.title }}
      </div>
      <div v-if="stepDataRef.subTitle" class="form-step__subtitle mt-4">{{ stepDataRef.subTitle }}</div>

      <Ui2Form
        class="form-step__form mt-40"
        :data-component="stepDataRef.component"
        @submit="() => (stepDataRef.nextComponent ? goToNextStep() : sendForm())"
      >
        <component :is="stepDataRef.component" v-if="stepDataRef.component" />

        <Ui2Button
          v-if="stepDataRef.nextComponent"
          html-type="submit"
          :label="$mopI18n.t('common.action.continue')"
          class="mt-40"
          size="lg"
        />
        <Ui2Button
          v-else
          :label="$mopI18n.t('common.send')"
          size="lg"
          class="mt-40"
          html-type="submit"
          :loading="loadingRef.loading"
        />
      </Ui2Form>
      <!-- used for preloading -->
      <div v-show="false">
        <component :is="stepDataRef.nextComponent" v-if="stepDataRef.nextComponent" is-preview-mode />
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.form-step {
  flex-grow: 1;
}
.form-step__form {
  max-width: 460px;
  width: 100%;

  @include v2-apply-upto(mobile) {
    max-width: none;
  }
}
.form-step__back {
  margin-left: calc($space-16 * -1);
}
.form-step__title {
  @include v2-text-style('3xl', 'highlight');
}
.form-step__subtitle {
  @include v2-text-style('sm');

  color: $color-text-body-secondary;
}
.fade-enter-active,
.fade-leave-active {
  transition: all var(--animation-speed) ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
